<template>
  <div id="list1" class="dropdown-check-list" tabindex="100">
    <span
      class="anchor"
      @click="isVisible=!isVisible"
    >{{ $t('Event.FILTER.CHECK_LIST.CHECKLIST_TITLE') }} <i class="fas fa-chevron-down"></i>
    </span>
    <ul
      id="items"
      class="items"
      :class="{ 'visible d-block': isVisible, 'd-none': !isVisible }"
    >
      <li>
        <input type="checkbox" @click="selectAll" v-model="allSelected"  @change="handleInput" />
        <label class="checklabel">{{ $t('Event.FILTER.CHECK_LIST.LABEL_AGE_ALL') }}</label>
      </li>
      <li
        v-for="option in options"
        :key="option.value"
      >
        <input
          v-model="selected"
          type="checkbox"
          name="checklist-option"
          :value="option.id"
          :id="option.id"
          @change="handleInput"
        />
        <label class="checklabel">{{ option.name }}</label>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'EventFilterChecklist',

    props: {
      title: {
        type: String,
        default: ''
      },
      events: {
        type: Array,
        default: () => []
      },
      options: {
        type: Array,
        default: () => []
      },
      value: {
        type: Array
      }
    },

    data () {
      return {
        isVisible: false,
        selected: this.value,
        allSelected: false,
      }
    },

    watch: {
      selected(value) {
        if (value.length !== this.options.length && this.allSelected) {
          this.allSelected = false
        }
      }
    },

    methods: {
      handleInput () {
        this.$emit('input', this.selected)
      },
      selectAll () {
        this.selected= [];

        if(!this.allSelected) {
          for (let option in this.options) {
            this.selected.push(this.options[option].id);
          }
        }
      },

      closeDropdown () {
        this.isVisible = false
      }
    }
  }
</script>
