<template>
  <div class="eventlisting">
    <div class="container container-eventlistings">
      <div class="row row-eventlistings">
        <div class="col-12 event-header">
          <div class="head event-head">
            <h3>{{ eventConfiguration.title }}</h3>
            <p v-html="eventConfiguration.description"></p>
          </div>

          <div v-if="!loading && events && events.length <= 0">
            <h2> {{ this.settingData.event_no_period_message }} </h2>
          </div>
          <div class="head-eventdroplist" v-else>
          <div class="head-eventdropdown">
            <div v-if="!hideFilter" class="head-dropdown dropdown-one">
              <div class="head-dropdown-box head-dropdown-boxone">
                <select
                  v-model="filter.event_period_id"
                  id="txtTitle"
                  class="form-control selectpicker"
                  data-style="btn-select-picker"
                >
                  <option
                    v-for="period in filteredPeriodCategories"
                    :value="period.id"
                    :key="period.id"
                  >{{ period.name }}</option>
                </select>
              </div>
              <!-- <div class="head-dropdown-box head-dropdown-boxtwo">
              </div> -->
            </div>
            <div v-if="!hideFilter" class="head-dropdown dropdown-two">
              <div class="head-dropdown-box head-dropdown-boxtwo">
                <event-filter-checklist
                  v-model="filter.event_category_id"
                  :events="events"
                  :title="$t('Event.FILTER.CHECK_LIST.CHECKLIST_TITLE')"
                  :options="ageOptions"
                  @input="loadEvents"
                  ref="eventFilterChecklist"
                />
              </div>
            </div>
            </div>
            <div class="row">
            <div class="event-list-section col-12 mt-5">
              <span v-if="!loading && filteredEvents && filteredEvents <= 0">
                <div class="col-md-6 col-sm-12 col-xl-4 col-lg-4 mb-4 card-event-msg">
                  <h2> {{ settingData.no_event_message }} </h2>
                </div>
              </span>
              <span v-else>
                <div class="accordion" id="accordionExample">
                  <span v-for="(eventDate, i) in this.eventDates" :key="eventDate">
                    <event-accordion :title="formatDate(eventDate)" @show="onEventDateExpand(i)" @shown="onEventExpanded(i)" ref="eventDate">
                      <template v-for="event in filteredEvents">

                        <div
                          
                          :key="event.id"
                          class="col-md-6 col-sm-12 col-xl-4 col-lg-4 mb-4 card-event"
                          v-if="event.event_date == eventDate"
                        >
                          <event-box
                            :event-data="event"
                            :show-register-button="!hideFilter"
                            :maximum-limit="settingData.maximum_limit_message_for_listing"
                            v-if="event.event_date == eventDate"
                          />
                        </div>
                      </template>
                    </event-accordion>
                  </span>
                </div>
              </span>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  .btn.btn-link
  {
    text-decoration: none;
    color: #212529;
  }
  .card-body
  {
    padding: 1.25rem 0;
  }
  .card
  {
    border: none;
  }
  .card:first-child
  {
    border-top: 1px solid rgba(0,0,0,.125);
  }
  .card-header
  {
    padding: 0px;
  }
  .btn:focus, .btn.focus
  {
    box-shadow: unset;
  }
  span.anchor svg {
    float: right;
    margin-top: 5px;
  }
</style>
<script>
  import { mapGetters } from 'vuex'
  import EventBox from '../components/event/EventBox'
  import EventFilterChecklist from '../components/event/EventFilterChecklist'
  import { format } from 'date-fns';
  import EventAccordion from '../components/event/EventAccordion';
  // import ExternalEventApi from '@/service/external-events.js'

  export default {
    name: 'Events',

    components: {
      EventBox,
      EventFilterChecklist,
      EventAccordion
    },

    data () {
      return {
        ageOptions: [],
        eventCategories: [],
        filter: {
          event_category_id: [],
          event_period_id: null
        },
        loading: false,
        events: [],
        hideFilter: true,
        periodCategories: [],
        eventDates: [],
      }
    },

    computed: {
      ...mapGetters({ settingsId: 'getSettingId', eventConfiguration: 'getEventConfiguration', settingData : 'getSettingData' }),

      filteredPeriodCategories () {
        const periodsIds = this.events.map(ev => ev.event_period_id)

        return this.periodCategories.filter(periodCategory => periodsIds.includes(periodCategory.id))
      },

      filteredEvents () {
          return this.events.filter(ev => ev.event_period_id === this.filter.event_period_id)
      }
    },

    watch: {
        filteredPeriodCategories() {
          this.$nextTick(() => {
                this.reInitializeSelectPicker()
          });
        },

        'filter.event_period_id'() {
          this.loadEvents()
        }
    },

    mounted () {
      this.loadEvents()
      if( this.$route.query && this.$route.query.back_btn){
        this.$store.commit('SET_EVENTS_BACK_BUTTON_FLAG', this.$route.query.back_btn );
      }
      // Reload the Bootstrap select picker
      this.reInitializeSelectPicker()
    },

    methods: {
      loadEvents () {
        this.loading = true;
        this.$root.$load(true)
        if (this.settingData.allowed_excel === "1") {
          this.hideFilter = true
          this.loadXMLEvents()
        } else {
            this.loadFilterData()
            this.loadDirectEvents()
        }
      },

      loadDirectEvents () {
        const params = this.getParams()
        this.$http.post('get-event-list', params)
          .then((response) => {
            if (response.status === 200) {
              const items = response.data.dataset
              let temp = items.sort((a,b)=> (a.time > b.time ? 1 : -1)) 
              this.events = temp
              this.eventDates = this.filteredEvents.map(ev => ev.event_date);
              this.eventDates = this.eventDates.filter((v, i, a) => a.indexOf(v) === i);
              this.eventDates.sort( ( a, b) => {
                return new Date(a) - new Date(b);
              });

              if (this.filter.event_period_id === null) {
                const filteredPeriodCategories = this.filteredPeriodCategories;
                this.filter.event_period_id = this.filteredPeriodCategories.length ? filteredPeriodCategories[0].id : null
              }
              this.$store.commit('SET_EVENTS', items);

              const {
                event_banner,
                event_description,
                event_title
              } = response.data.data;

              this.$store.commit('SET_EVENT_CONFIGURATION', {
                bannerUrl: event_banner,
                title: event_title,
                description: event_description
              })
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => {
            this.$root.$load(false)
            this.loading = false;
          })
      },

      loadXMLEvents () {
        const param = { setting_id: this.settingsId }
        this.$http.post('get-excel-data', param)
          .then((response) => {
            const xmlData = response.data.data
            const xml = this.$x2js.xml2js(xmlData)
            this.parseXmlDataToEventObject(xml)
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
      },

      loadFilterData () {
        const settingsId = this.settingsId
        const periodId = this.filter.event_period_id
        return this.$http.post('event-category', { setting_id: settingsId, 'period_id': periodId })
          .then((response) => {
            const data = response.data.data
            this.hideFilter = false
            this.ageOptions = data.event_category
            this.eventCategories = data.event_category
            this.periodCategories = data.event_period
            if (this.filter.event_period_id === null) {
              this.filter.event_period_id = this.filteredPeriodCategories.length ? this.filteredPeriodCategories[0].id : null
            }
            this.$nextTick(() => {
              this.reInitializeSelectPicker()
            })
          })
          .catch((e) => { console.log(e) })
      },

      getParams () {
        const { event_category_id } = this.filter
        const params = {
          setting_id: this.settingsId,
          event_category_id,
        }

        return params
      },

      parseXmlDataToEventObject (xml) {
        if (xml && typeof xml === 'object' && xml.RECREATHEEK) {
          const doc = xml.RECREATHEEK
          const events = []

          if (doc.PROGRAMMA && doc.PROGRAMMA.length > 0) {
            const programs = doc.PROGRAMMA
            programs.forEach(program => {
              const subProgramsDates = program.DAGPROGRAMMA && program.DAGPROGRAMMA.DATUM

              if (subProgramsDates && subProgramsDates.length > 0) {
                subProgramsDates.forEach(subProgram => {
                  if (subProgram) {
                    if (
                      subProgram.ACTIVITEITEN &&
                      subProgram.ACTIVITEITEN.ACTIVITEIT &&
                      subProgram.ACTIVITEITEN.ACTIVITEIT.length > 0
                    ) {
                      const activities = subProgram.ACTIVITEITEN.ACTIVITEIT

                      activities.forEach(activity => {
                        let image = ''
                        let capacity = ''
                        if (activity.DOELGROEPEN && activity.DOELGROEPEN.DOELGROEP) {
                          image = activity.DOELGROEPEN.DOELGROEP.AFBEELDING
                          capacity = activity.DOELGROEPEN.DOELGROEP.NAAM
                        }
                        const event = {
                          address: activity.LOCATIE,
                          capacity: capacity,
                          description: activity.OMSCHRIJVING,
                          id: activity.ACTIVITEITID,
                          image: image,
                          time: activity.TIJD,
                          title: activity.TITEL,
                          endTime: activity.EINDTIJD,
                          expense: activity.DUUR,
                        }

                        events.push(event)
                      })
                    }
                  }
                })
              }
            });
          }

          this.events = events
        } else {
          this.$toast.open({
            message: this.$i18n.t('Event.TOAST_MESSAGE.INVALID_EVENT_XML_DATA'),
            type: 'error'
          })
        }
      },

      reInitializeSelectPicker () {
        window.$(".selectpicker").selectpicker('refresh')
        window.$(".selectpicker").on('shown.bs.select', this.periodsShown)
      },

      periodsShown () {
        this.$refs.eventFilterChecklist.closeDropdown()
      },

      formatDate(d)
      {
        var today  = new Date(d);
        return [
          this.$t('core.Base.DAYS_OF_WEEK.' + format(today,'EEEE')),
          today.getDate(),
          this.$t('core.Base.MONTH.' + format(today, 'MMMM'))
        ].join(' ');
      },

      onEventDateExpand(i) {
        this.$refs.eventDate.forEach((accordion, index) => {
          if (i !== index) {
            accordion.hide();
          }
        })
      },

      onEventExpanded(i) {
        this.$root.$emit('events-expanded');
        
        this.$nextTick(() => {
          const rect = this.$refs.eventDate[i].$el.getBoundingClientRect();

          document.documentElement.scrollBy({
            top: rect.top - 100,
            behavior: 'smooth'
          })
        });
      }
    }
  }
</script>
