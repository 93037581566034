<template>
  <div
    class="event-box"
    :class="{ 'show': (expandable && expanded) || expandable === false, 'event-can-be-subscribed': eventData.can_subscription }"
  >
    <div class="event-img">
      <img
        :src="eventData.image || defaultImage"
        :alt="eventData.title"
      />
    </div>
    <div class="event-txt">
      <h4>{{ eventData.title }}</h4>
      <ul>
        <li class="time pb-2"><i class="far fa-clock mr-2"></i>{{ eventData.time }}</li>
        <li class="home pb-2"><i class="fas fa-home mr-2"></i>{{ eventData.address }}</li>
        <li class="user pb-2"><i class="far fa-user mr-2"></i>{{ eventData.common_category.category_name }}</li>
      </ul>
    </div>
    <div
      class="event-txt-second"
    >
      <div ref="description" class="event-text-desc" v-html="eventData.description"></div>
      <h5 v-if="eventData.can_subscription && !(eventData.registered_events === eventData.capacity) && (eventData.capacity - eventData.registered_events) <= 5"> {{ $t('Event.SUBSCRIPTION_AVAILABLE') }}{{ eventData.capacity - eventData.registered_events }} {{ $t('Event.SUBSCRIPTION_AFTER_MESSAGE_AVAILABLE') }} </h5>
      <span v-if="eventData.can_subscription">
          <span v-if="eventData.registered_events < eventData.capacity">
            <span v-if="eventData.latest_time != null">
              <span style="font-size: 20px;" v-if="getLatestTime(eventData.event_date,eventData.latest_time) < current_time">
                <br />
                {{ $t('Event.REGISTRATIONS_NO_LONGER') }} 
              </span>
              <span v-else>
                <router-link class="btn" :to="`events/register/${eventData.id}`">
                  {{ $t('Event.FORM.LABEL_EVENT_REGISTER') }}
                </router-link>
              </span>
            </span>
            <span v-else>
              <router-link class="btn" :to="`events/register/${eventData.id}`">
                {{ $t('Event.FORM.LABEL_EVENT_REGISTER') }}
              </router-link>
            </span>
          </span>
          <span v-else>
            <br />
            {{ maximumLimit }}
          </span>
      </span>
      <div class="event-plusicon" ref="plusIcon" :style="{ display: expandable ? '' : 'none' }">
        <a href="#" @click.prevent="toggleCollapse">
          <span> {{ expanded ? '-' : '+' }} </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EventBox',

  props: {
    eventData: {
      type: Object,
      default: () => {}
    },
    showRegisterButton: {
      type: Boolean,
      default: true
    },
    maximumLimit: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      expanded: false,
      expandable: false,
      defaultImage: '/banners/event-img.png'
    }
  },

  mounted() {
    this.isExpandableCheck();

    this.$root.$on('events-expanded', this.isExpandableCheck);
  },

  computed: {
    current_time(){
      let today = new Date()
      return today.getTime();
    }
  },

  methods: {
    getLatestTime(date,time){
      date = date.replace(/^\s+|\s+$/gm,'');
      time = time.replace(/^\s+|\s+$/gm,'');
      let cmp_date = new Date(date+' '+time+':00');
      // console.log(date+' '+time+':00', cmp_date.getTime())
      return cmp_date.getTime();
    },
    toggleCollapse () {
      this.expanded = !this.expanded

      if (this.expanded) {
        this.$refs.description.style.maxHeight =  this.$refs.description.scrollHeight+'px';
      } else {
        this.$refs.description.style.maxHeight =  '';
      }
    },

    isExpandableCheck () {
      this.$nextTick(() => {
        if (this.$refs.description && this.$refs.description.scrollHeight >= 56) {
          this.expandable = true;
        }
      });
    },
    // register_method(id,time){
    //   if(time != null){
    //     let time_cmp = time.replace(/^\s+|\s+$/gm,'')
    //     let today = new Date()
    //     let current_time = today.getHours()+':'+today.getMinutes()
    //     if(time_cmp < current_time){
    //       this.$toast.open ({
    //         message: this.$i18n.t('Event.REGISTRATIONS_NO_LONGER'),
    //         type: 'error',
    //         position: 'bottom-right'
    //       })
    //     }else{
    //       this.$router.push('events/register/'+id);   
    //     }
    //   }else{
    //      this.$router.push('events/register/'+id);
    //   }
    // }
  }
}
</script>
