<template>
    <div class="card">
        <div class="card-header">
            <h2 class="mb-0" @click="toggle">
                <button class="btn btn-link button-element event-accordion-button" type="button">
                {{ title }}
                </button>
            </h2>
        </div>
        <div class="collapse" ref="collapsible">
            <div class="card-body">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: null
        }
    },

    mounted() {
        window.$('.collapse').first().addClass('show');
        
        window.$(this.$refs.collapsible).on('shown.bs.collapse', () => {
            this.$emit('shown')
        });

        window.$(this.$refs.collapsible).on('show.bs.collapse', () => {
            this.$emit('show')
        });

        window.$(this.$refs.collapsible).on('hidden.bs.collapse', () => {
            this.$emit('hidden')
        })

        window.$(this.$refs.collapsible).on('hide.bs.collapse', () => {
            this.$emit('hide')
        })
    },

    methods: {
        toggle() {
            window.$(this.$refs.collapsible).collapse('toggle')
        },

        show() {
            window.$(this.$refs.collapsible).collapse('show')
        },

        hide() {
            window.$(this.$refs.collapsible).collapse('hide')
        }
    }

}
</script>